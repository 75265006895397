import { FETCH_MATCHES, FETCH_MATCHES_COUNT } from "../../constants/ActionTypes";

const INIT_STATE = {
  matches: [],
  allMatchCount: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_MATCHES: {
      return { ...state, matches: action.payload };
    }
    case FETCH_MATCHES_COUNT: {
      return { ...state, allMatchCount: action.payload };
    }

    default:
      return state;
  }
}
