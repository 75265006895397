import { FETCH_SERIES, FETCH_SERIES_COUNT } from "../../constants/ActionTypes";

const INIT_STATE = {
  series: [],
  allSeriesCount: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SERIES: {
      return { ...state, series: action.payload };
    }
    case FETCH_SERIES_COUNT: {
      return { ...state, allSeriesCount: action.payload };
    }

    default:
      return state;
  }
}
