import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import { DB_PREFIX } from "../config/apiConfig";

import { config } from '../constants/config';
let adminType = config.type;

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))} />
      <Route path={`${match.url}site-options`} component={asyncComponent(() => import('./SiteOptions'))} />

      {/* Post Routes */}
      <Route path={`${match.url}all-posts`} component={asyncComponent(() => import('./Posts/AllPosts'))} />
      <Route path={`${match.url}add-post`} component={asyncComponent(() => import('./Posts/AddPost'))} />
      <Route path={`${match.url}edit-post/:id`} component={asyncComponent(() => import('./Posts/EditPost'))} />
      <Route path={`${match.url}view-post/:id`} component={asyncComponent(() => import('./Posts/ViewPost'))} />
      <Route path={`${match.url}all-categories`} component={asyncComponent(() => import('./Categories'))} />
      <Route path={`${match.url}all-tags`} component={asyncComponent(() => import('./Tags'))} />
      <Route path={`${match.url}post-sections`} component={asyncComponent(() => import('./Posts/PostSections'))} />

      {/* Comments Routes */}
      <Route path={`${match.url}comments`} component={asyncComponent(() => import('./Comments'))} />

      {/* Album Routes */}
      {/* <Route path={`${match.url}add-album`} component={asyncComponent(() => import('./Albums/AddAlbum'))} />
      <Route path={`${match.url}all-albums`} component={asyncComponent(() => import('./Albums/AllAlbums'))} />
      <Route path={`${match.url}view-album/:id`} component={asyncComponent(() => import('./Albums/ViewAlbum'))} />
      <Route path={`${match.url}edit-album/:id`} component={asyncComponent(() => import('./Albums/EditAlbum'))} /> */}

      {/* Photos Routes */}
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}add-photo`} component={asyncComponent(() => import('./Photos/AddPhoto'))} />
      }
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}all-photos`} component={asyncComponent(() => import('./Photos/AllPhotos'))} />
      }
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}edit-photo/:id`} component={asyncComponent(() => import('./Photos/EditPhoto'))} />
      }

      {/* Video Routes */}
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}add-video`} component={asyncComponent(() => import('./Videos/AddVideo'))} />
      }
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}all-videos`} component={asyncComponent(() => import('./Videos/AllVideos'))} />
      }
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}edit-video/:id`} component={asyncComponent(() => import('./Videos/EditVideo'))} />
      }

      {/* Poll Routes */}
      <Route path={`${match.url}add-poll`} component={asyncComponent(() => import('./Polls/AddPoll'))} />
      <Route path={`${match.url}all-polls`} component={asyncComponent(() => import('./Polls/AllPolls'))} />
      <Route path={`${match.url}edit-poll/:id`} component={asyncComponent(() => import('./Polls/EditPoll'))} />
      <Route path={`${match.url}view-poll/:id`} component={asyncComponent(() => import('./Polls/ViewPoll'))} />


      {/* Teams Routes */}
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}add-team`} component={asyncComponent(() => import('./Teams/AddTeam'))} />
      }
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}all-teams`} component={asyncComponent(() => import('./Teams/AllTeams'))} />
      }
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}edit-team/:id`} component={asyncComponent(() => import('./Teams/EditTeam'))} />
      }

      {/* Series Routes */}
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}add-series`} component={asyncComponent(() => import('./Series/AddSeries'))} />
      }
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}all-series`} component={asyncComponent(() => import('./Series/AllSeries'))} />
      }
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}edit-series/:id`} component={asyncComponent(() => import('./Series/EditSeries'))} />
      }

      {/* Players Routes */}
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}add-player`} component={asyncComponent(() => import('./Players/AddPlayer'))} />
      }
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}all-players`} component={asyncComponent(() => import('./Players/AllPlayers'))} />
      }
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}edit-player/:id`} component={asyncComponent(() => import('./Players/EditPlayer'))} />
      }

      {/* Match Routes */}
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}add-match`} component={asyncComponent(() => import('./Match/AddMatch'))} />
      }
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}all-matches`} component={asyncComponent(() => import('./Match/AllMatches'))} />
      }
      {DB_PREFIX == "bden" &&
        <Route path={`${match.url}edit-match/:id`} component={asyncComponent(() => import('./Match/EditMatch'))} />
      }

      {/* Internal Link Routes */}
      <Route path={`${match.url}internal-links`} component={asyncComponent(() => import('./InternalLinks'))} />

      {/* Image Optimization Routes */}
      {/* <Route path={`${match.url}image-optimization`} component={asyncComponent(() => import('./ImageOptimization'))} /> */}

      {/* Menu Routes */}
      <Route path={`${match.url}menu-items`} component={asyncComponent(() => import('./Menu/MenuItems'))} />
      <Route path={`${match.url}header-menu`} component={asyncComponent(() => import('./Menu/HeaderMenu'))} />
      <Route path={`${match.url}footer-menu`} component={asyncComponent(() => import('./Menu/FooterMenu'))} />
      <Route path={`${match.url}copyright-menu`} component={asyncComponent(() => import('./Menu/CopyrightMenu'))} />

      {/* Pages Routes */}
      <Route path={`${match.url}all-pages`} component={asyncComponent(() => import('./Pages/AllPages'))} />
      <Route path={`${match.url}add-page`} component={asyncComponent(() => import('./Pages/AddPage'))} />
      <Route path={`${match.url}edit-page/:id`} component={asyncComponent(() => import('./Pages/EditPage'))} />
      <Route path={`${match.url}about-testimonial`} component={asyncComponent(() => import('./About/AboutTestimonial'))} />
      <Route path={`${match.url}about-content`} component={asyncComponent(() => import('./About/AboutContent'))} />
      <Route path={`${match.url}faqs`} component={asyncComponent(() => import('./Faq/Faq'))} />

      {/* User Routes */}
      <Route path={`${match.url}all-users`} component={asyncComponent(() => import('./Users/AllUsers'))} />
      <Route path={`${match.url}add-user`} component={asyncComponent(() => import('./Users/AddUser'))} />
      <Route path={`${match.url}edit-user/:id`} component={asyncComponent(() => import('./Users/EditUser'))} />
      <Route path={`${match.url}profile`} component={asyncComponent(() => import('./Users/Profile'))} />
      <Route path={`${match.url}user/:id`} component={asyncComponent(() => import('./Users/ViewProfile'))} />

      {/* Guest Routes */}
      <Route path={`${match.url}all-guests`} component={asyncComponent(() => import('./Users/AllUsers'))} />
      <Route path={`${match.url}add-guest`} component={asyncComponent(() => import('./Guest/AddGuest'))} />
      <Route path={`${match.url}edit-guest/:id`} component={asyncComponent(() => import('./Users/EditUser'))} />

      {/* Subscriber Routes */}
      <Route path={`${match.url}all-subscribers`} component={asyncComponent(() => import('./Subscribers/AllSubscribers'))} />
      <Route path={`${match.url}send-message-to-subscriber`} component={asyncComponent(() => import('./Subscribers/SendMessage'))} />

      {/* Ad Routes */}
      <Route path={`${match.url}ads`} component={asyncComponent(() => import('./Advertise'))} />

      {/* Contact Routes */}
      <Route path={`${match.url}all-contacts`} component={asyncComponent(() => import('./Contacts/AllContacts'))} />
      <Route path={`${match.url}view-contact/:id`} component={asyncComponent(() => import('./Contacts/ViewContact'))} />
    </Switch>
  </div>
);

export default App;
