import { combineReducers } from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Category from "./Category";
import Advertise from "./Advertise";
import Album from "./Album";
import Team from "./Team";
import Series from "./Series";
import Players from "./Palyers";
import Contacts from "./Contacts";
import Match from "./Match";
import Poll from "./Poll";
import AlbumCategory from "./AlbumCategory";
import Tag from "./Tag";
import Comment from "./Comment";
import CommentReply from "./CommentReply";
import Post from './Posts';
import PostSections from './PostSections';
import Testimonials from './Testimonials';
import Faqs from './Faq';
import User from './User';
import Subsciber from './Subscription';
import Menu from './Menu';
import MenuGroup from './MenuGroup';
import InternalLink from './InternalLink';
import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  commonData: Common,
  category: Category,
  advertise: Advertise,
  album: Album,
  team: Team,
  series: Series,
  players: Players,
  contacts: Contacts,
  internalLink: InternalLink,
  menu: Menu,
  menuGroup: MenuGroup,
  matches: Match,
  polls: Poll,
  albumCategory: AlbumCategory,
  tag: Tag,
  post: Post,
  postSections: PostSections,
  testimonials: Testimonials,
  faqs: Faqs,
  comment: Comment,
  commentReply: CommentReply,
  user: User,
  subscriber: Subsciber
});
