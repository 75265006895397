import { INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET, FETCH_DB_permission, FETCH_permission } from "../../constants/ActionTypes";

const INIT_STATE = {
  token: sessionStorage.getItem('token'),
  initURL: '',
  authUser: JSON.parse(sessionStorage.getItem('user')),
  permission: sessionStorage.getItem('_bdct_prmsns'),
  db_permission: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        permission: null,
        initURL: ''
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }
    case FETCH_permission: {
      return { ...state, permission: action.payload };
    }
    case FETCH_DB_permission: {
      return { ...state, db_permission: action.payload };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    default:
      return state;
  }
}
