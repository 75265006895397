import { FETCH_POSTS, ADD_POST, FETCH_COUNT, FETCH_PUBLISH_COUNT, FETCH_PENDING_COUNT, FETCH_DRAFT_COUNT, FETCH_TRASHED_COUNT, FETCH_CATEGORIES, FETCH_FIRST_POST_DATE } from "../../constants/ActionTypes";

const INIT_STATE = {
  categories: [],
  posts: null,
  allPostCount: 0,
  publishedPostCount: 0,
  pendingPostCount: 0,
  draftPostCount: 0,
  trashedPostCount: 0,
  firstPostDate: 0
};

export default (state = INIT_STATE, action) => {

  switch (action.type) {

    case FETCH_CATEGORIES: {
      return { ...state, categories: action.payload };
    }

    case FETCH_POSTS: {
      return { ...state, posts: action.payload };
    }
    case FETCH_FIRST_POST_DATE: {
      return { ...state, firstPostDate: action.payload };
    }

    case FETCH_COUNT: {
      return { ...state, allPostCount: action.payload };
    }
    case FETCH_PUBLISH_COUNT: {
      return { ...state, publishedPostCount: action.payload };
    }
    case FETCH_PENDING_COUNT: {
      return { ...state, pendingPostCount: action.payload };
    }
    case FETCH_DRAFT_COUNT: {
      return { ...state, draftPostCount: action.payload };
    }
    case FETCH_TRASHED_COUNT: {
      return { ...state, trashedPostCount: action.payload };
    }

    case ADD_POST: {
      return {
        ...state,
        posts: [action.payload, ...state.posts]
      }
    }

    default:
      return state;
  }
}
