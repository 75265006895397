import { FETCH_CONTACTS, FETCH_CONTACTS_COUNT } from "../../constants/ActionTypes";

const INIT_STATE = {
  contacts: [],
  allContactsCount: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CONTACTS: {
      return { ...state, contacts: action.payload };
    }
    case FETCH_CONTACTS_COUNT: {
      return { ...state, allContactsCount: action.payload };
    }

    default:
      return state;
  }
}
