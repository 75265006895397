// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';

//categories const...
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_TAGS = 'FETCH_CATEGORIES_TAGS';
export const FETCH_CATEGORIES_TAGS_BANGLA = 'FETCH_CATEGORIES_TAGS_BANGLA';
export const FETCH_PARENT_CATEGORIES = 'fetch_parent_categories';
export const ADD_CATEGORIE = 'ADD_CATEGORIE';

//tags const...
export const FETCH_TAGS = 'FETCH_TAGS';
export const ADD_TAG = 'ADD_TAG';

//posts const...
export const FETCH_POSTS = 'fetch_posts';
export const ADD_POST = 'add_post';
export const FETCH_COUNT = 'fetch_count';
export const FETCH_PHOTO_COUNT = 'FETCH_PHOTO_COUNT';
export const FETCH_PUBLISH_COUNT = 'fetch_publish_count';
export const FETCH_PENDING_COUNT = 'fetch_pending_count';
export const FETCH_DRAFT_COUNT = 'fetch_draft_count';
export const FETCH_TRASHED_COUNT = 'fetch_trashed_count';

//comments const...
export const FETCH_COMMETNS = 'fetch_comments';
export const ADD_COMMENT = 'add_comment';
export const FETCH_COMMENT_COUNT = 'fetch_comment_count';
export const FETCH_MINE_COMMENT_COUNT = 'fetch_mine_comment_count';
export const FETCH_PENDING_COMMENT_COUNT = 'fetch_pending_comment_count';
export const FETCH_APPROVED_COMMENT_COUNT = 'fetch_approved_comment_count';
export const FETCH_TRASHED_COMMENT_COUNT = 'fetch_trashed_comment_count';
export const FETCH_SPAM_COMMENT_COUNT = 'fetch_spam_comment_count';
export const FETCH_COMMETN_REPLY = 'fetch_comment_reply';
export const FETCH_COMMETN_REPLY_COUNT = 'fetch_comment_reply_count';
export const FETCH_FIRST_POST_DATE = 'fetch_first_post_date';

// Album Const
export const FETCH_ALBUM_CATEGORIES = 'fetch_album_categories';

//role const
export const FETCH_permission = 'fetch_permission';
export const FETCH_DB_permission = 'fetch_db_permission';
export const FETCH_ADMIN = 'fetch_admin';
export const FETCH_USER_COUNT = 'fetch_user_count';
export const FETCH_USER = 'fetch_user';
export const FETCH_USER_ALL = 'FETCH_USER_ALL';
export const FETCH_USER_ADMINISTRATOR = 'FETCH_USER_ADMINISTRATOR';
export const FETCH_USER_EDITOR = 'FETCH_USER_EDITOR';
export const FETCH_USER_AUTHOR = 'FETCH_USER_AUTHOR';
export const FETCH_USER_CONTRIBUTOR = 'FETCH_USER_CONTRIBUTOR';
export const FETCH_USER_SUBSCRIBER = 'FETCH_USER_SUBSCRIBER';



export const FETCH_ADS = 'FETCH_ADS';
export const FETCH_POST_SETTINGS = 'FETCH_POST_SETTINGS';
export const FETCH_TESTIMONIALS = 'FETCH_TESTIMONIALS';
export const FETCH_ALBUMS = 'FETCH_ALBUMS';


export const FETCH_PHOTOS = 'FETCH_PHOTOS';
export const FETCH_VIDEOS = 'FETCH_VIDEOS';
export const FETCH_VIDEOS_COUNT = 'FETCH_VIDEOS_COUNT';


export const FETCH_TEAMS = 'FETCH_TEAMS';
export const FETCH_SERIES = 'FETCH_SERIES';
export const FETCH_PLAYERS = 'FETCH_PLAYERS';

export const FETCH_SERIES_COUNT = 'FETCH_SERIES_COUNT';
export const FETCH_PLAYERS_COUNT = 'FETCH_PLAYERS_COUNT';


//subscription 

export const FETCH_SUBSCRIPTION = 'FETCH_SUBSCRIPTION';
export const FETCH_SUBSCRIPTION_COUNT = 'FETCH_SUBSCRIPTION_COUNT';

export const FETCH_MATCHES = 'FETCH_MATCHES';
export const FETCH_MATCHES_COUNT = 'FETCH_MATCHES_COUNT';

export const FETCH_MENUS = 'FETCH_MENUS';
export const FETCH_MENU_GROUP = 'FETCH_MENU_GROUP';

export const FETCH_CONTACTS = 'FETCH_CONTACTS';
export const FETCH_CONTACTS_COUNT = 'FETCH_CONTACTS_COUNT';

export const FETCH_INTERNAL_LINKS = 'FETCH_INTERNAL_LINKS';


export const FETCH_POLLS = 'FETCH_POLLS';
export const FETCH_POLLS_COUNT = 'FETCH_POLLS_COUNT';

export const FETCH_FAQS = 'FETCH_FAQS';

