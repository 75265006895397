import { FETCH_ALBUM_CATEGORIES } from "../../constants/ActionTypes";

const INIT_STATE = {
  albumCategories: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case FETCH_ALBUM_CATEGORIES: {
      return { ...state, albumCategories: action.payload };
    }

    default:
      return state;
  }
}
