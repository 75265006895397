import { FETCH_TESTIMONIALS } from "../../constants/ActionTypes";

const INIT_STATE = {
  testimonials: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case FETCH_TESTIMONIALS: {
      return { ...state, testimonials: action.payload };
    }

    default:
      return state;
  }
}
