import { FETCH_CATEGORIES, FETCH_CATEGORIES_TAGS, FETCH_CATEGORIES_TAGS_BANGLA, FETCH_PARENT_CATEGORIES, FETCH_TAGS } from "../../constants/ActionTypes";

const INIT_STATE = {
  tags: [],
  categories: [],
  categoriesTags: [],
  categoriesTagsBangla: [],
  parentCategories: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case FETCH_CATEGORIES: {
      return { ...state, categories: action.payload };
    }
    case FETCH_CATEGORIES_TAGS: {
      return { ...state, categoriesTags: action.payload };
    }
    case FETCH_CATEGORIES_TAGS_BANGLA: {
      return { ...state, categoriesTagsBangla: action.payload };
    }

    case FETCH_TAGS: {
      return { ...state, tags: action.payload };
    }

    case FETCH_PARENT_CATEGORIES: {
      return { ...state, parentCategories: action.payload };
    }

    default:
      return state;
  }
}
