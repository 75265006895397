import { FETCH_TAGS, ADD_TAG } from "../../constants/ActionTypes";

const INIT_STATE = {
  tags: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case FETCH_TAGS: {
      return { ...state, tags: action.payload };
    }

    case ADD_TAG: {
      return { ...state, tags: [...state.tags, action.payload] };
    }

    default:
      return state;
  }
}
