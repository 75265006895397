import { FETCH_POLLS,  FETCH_POLLS_COUNT, } from "../../constants/ActionTypes";

const INIT_STATE = {
  polls: [],
  allPollsCount: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_POLLS: {
      return { ...state, polls: action.payload };
    }
    case FETCH_POLLS_COUNT: {
      return { ...state, allPollsCount: action.payload };
    }

    default:
      return state;
  }
}
