import { FETCH_SUBSCRIPTION, FETCH_SUBSCRIPTION_COUNT } from "../../constants/ActionTypes";

const INIT_STATE = {
    subscribers: [],
    allSubscribersCount: 0
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_SUBSCRIPTION: {
            return { ...state, subscribers: action.payload };
        }
        case FETCH_SUBSCRIPTION_COUNT: {
            return { ...state, allSubscribersCount: action.payload };
        }

        default:
            return state;
    }
}
