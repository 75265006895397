// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./NoirPro-Light.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./NoirPro-Light.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./NoirPro-Light.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./NoirPro-Regular.eot");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./NoirPro-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./NoirPro-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./NoirPro-Medium.eot");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./NoirPro-Medium.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./NoirPro-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_9___ = require("./NoirPro-SemiBold.eot");
var ___CSS_LOADER_URL_IMPORT_10___ = require("./NoirPro-SemiBold.woff");
var ___CSS_LOADER_URL_IMPORT_11___ = require("./NoirPro-SemiBold.woff2");
var ___CSS_LOADER_URL_IMPORT_12___ = require("./NoirPro-Bold.eot");
var ___CSS_LOADER_URL_IMPORT_13___ = require("./NoirPro-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_14___ = require("./NoirPro-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_15___ = require("./NoirPro-Heavy.eot");
var ___CSS_LOADER_URL_IMPORT_16___ = require("./NoirPro-Heavy.woff");
var ___CSS_LOADER_URL_IMPORT_17___ = require("./NoirPro-Heavy.woff2");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___);
var ___CSS_LOADER_URL_REPLACEMENT_15___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_15___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_16___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_16___);
var ___CSS_LOADER_URL_REPLACEMENT_17___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_17___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'NoirPro';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('embedded-opentype'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2');\n  font-weight: 300;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'NoirPro';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('embedded-opentype'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('woff2');\n  font-weight: 400;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'NoirPro';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('embedded-opentype'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('woff'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format('woff2');\n  font-weight: 500;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'NoirPro';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format('embedded-opentype'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format('woff'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format('woff2');\n  font-weight: 600;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'NoirPro';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_12___ + ") format('embedded-opentype'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_13___ + ") format('woff'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_14___ + ") format('woff2');\n  font-weight: 700;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'NoirPro';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_15___ + ") format('embedded-opentype'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_16___ + ") format('woff'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_17___ + ") format('woff2');\n  font-weight: 900;\n  font-style: normal;\n}\n", ""]);
// Exports
module.exports = exports;
