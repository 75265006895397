import { FETCH_MENUS } from "../../constants/ActionTypes";

const INIT_STATE = {
  menus: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_MENUS: {
      return { ...state, menus: action.payload };
    }

    default:
      return state;
  }
}
