import { FETCH_PLAYERS, FETCH_PLAYERS_COUNT } from "../../constants/ActionTypes";

const INIT_STATE = {
  players: [],
  allPlayersCount: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PLAYERS: {
      return { ...state, players: action.payload };
    }
    case FETCH_PLAYERS_COUNT: {
      return { ...state, allPlayersCount: action.payload };
    }

    default:
      return state;
  }
}
