import { FETCH_ALBUMS, FETCH_PHOTOS, FETCH_PHOTO_COUNT, FETCH_VIDEOS, FETCH_VIDEOS_COUNT } from "../../constants/ActionTypes";

const INIT_STATE = {
  albums: [],
  photos: [],
  videos: [],
  allPhotoCount: 0,
  allVideoCount: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALBUMS: {
      return { ...state, albums: action.payload };
    }
    case FETCH_PHOTOS: {
      return { ...state, photos: action.payload };
    }
    case FETCH_PHOTO_COUNT: {
      return { ...state, allPhotoCount: action.payload };
    }
    case FETCH_VIDEOS: {
      return { ...state, videos: action.payload };
    }
    case FETCH_VIDEOS_COUNT: {
      return { ...state, allVideoCount: action.payload };
    }

    default:
      return state;
  }
}
