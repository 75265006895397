import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET, FETCH_permission
} from "../../constants/ActionTypes";


import axios from 'axios'
import { notification } from 'antd';
import { config } from '../../constants/config';

import { encryptString } from '../../helper/cryptograpgy';
import { roles } from "../../constants/roles";
import { getMatchedValue } from "../../helper/MatchPermission";
let apiUrl = config.url.API_URL;

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

const openNotificationWithIcon = (type, msg, desc) => {
  notification[type]({
    message: msg,
    description: desc,
  });
};

export const userSignUp = ({ email, password, name }) => {
  // console.log(email, password, name);
  const signUpData = new FormData();
  signUpData.append('name', name);
  signUpData.append('email', email);
  signUpData.append('password', password);
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(apiUrl + 'api/v1/admin/register', signUpData
    ).then(({ data }) => {
      // console.log("data:", data);
      if (data.accessToken && data.refreshToken) {
        sessionStorage.setItem("token", data.accessToken);
        sessionStorage.setItem("refresh-token", data.refreshToken);
        axios.defaults.headers.common['x-access-token'] = "Bearer " + data.accessToken;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: data.accessToken });
        dispatch({ type: USER_DATA, payload: data.user });
      } else {
        // console.log("payload: data.error", data.msg);
        dispatch({ type: FETCH_ERROR, payload: "Network Error" });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      // console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({ user_email, user_pass }) => {
  const loginData = new FormData();
  loginData.append('user_email', user_email ? user_email : '');
  loginData.append('user_pass', user_pass ? user_pass : '');
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(apiUrl + 'api/v2/user/login', loginData)
      .then((response) => {
        // console.log(response);
        if (response.data.error === false) {
          if (response.data.accessToken) {
            const enc_perm = encryptString(getMatchedValue(response.data.user_info.permission, roles));
            const user = {
              ID: response.data.user_info.ID,
              user_email: response.data.user_info.user_email,
              user_login: response.data.user_info.user_login
            }
            sessionStorage.setItem("token", response.data.accessToken);
            sessionStorage.setItem("user", JSON.stringify(user));
            sessionStorage.setItem("_bdct_prmsns", enc_perm);

            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: USER_TOKEN_SET, payload: response.data.accessToken });
            dispatch({ type: FETCH_permission, payload: enc_perm });
            dispatch({ type: USER_DATA, payload: response.data.user_info });
          }
        }
        if (response.data.error == true) {
          dispatch({ type: FETCH_SUCCESS });
          openNotificationWithIcon('error', 'Signin', response.data.msg);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_SUCCESS });
        openNotificationWithIcon('error', 'Sign in', error.response.data.msg);
      });
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(apiUrl + 'api/v1/auth/me',
    ).then(({ data }) => {
      // console.log("userSignIn: ", data);
      if (data.result) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_DATA, payload: data.user });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      // console.log("Error****:", error.message);
    });
  }
};


export const userSignOut = () => {
  return (dispatch) => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("refresh-token");
    sessionStorage.removeItem("_bdct_prmsns");
    sessionStorage.removeItem("user");
    dispatch({ type: FETCH_START });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
    dispatch({ type: FETCH_SUCCESS });
  }

  // const accessToken = sessionStorage.getItem('token')
  // const RT = sessionStorage.getItem("refresh-token");
  // const refreshToken = JSON.parse(RT);
  // const logoutData = new FormData();
  // logoutData.append('refreshToken', refreshToken);
  // return (dispatch) => {
  //   dispatch({type: FETCH_START});

  //   axios.post(apiUrl+'api/v1/admin/logout', logoutData, { headers: {
  //         'x-access-token': `Bearer ${accessToken}` 
  //       }
  //     }).then(({data}) => {
  //     // console.log("log out",data)
  //     if (data) {
  //       sessionStorage.removeItem("token");
  //       sessionStorage.removeItem("refresh-token");
  //       sessionStorage.removeItem("_bdct_prmsns");
  //       dispatch({type: FETCH_SUCCESS});
  //       dispatch({type: SIGNOUT_USER_SUCCESS});
  //     } else {
  //       dispatch({type: FETCH_ERROR, payload: data.msg});
  //     }
  //   }).catch(function (error) {
  //     dispatch({type: FETCH_ERROR, payload: error.message});
  //     console.log("Error****:", error.message);
  //   });
  // }
};

export const forgotPassword = ({ email }, props) => {
  const e = email == 'undefined' ? '' : email;
  // console.log("e:", e);
  const postData = new FormData();
  postData.append('email', e);
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(apiUrl + 'api/v2/user/forgot-password', postData)
      .then(({ data }) => {
        // console.log('response', data);
        if (data.error === false) {
          dispatch({ type: FETCH_SUCCESS });
          openNotificationWithIcon('success', 'Forgot password', data.msg);
          props.history.push('/signin')
        }
        if (data.error == true) {
          dispatch({ type: FETCH_SUCCESS });
          openNotificationWithIcon('error', 'Forgot password', data.msg);
        }
        if (data.errors) {
          dispatch({ type: FETCH_SUCCESS });
          data.errors.map(e => {
            openNotificationWithIcon('error', e.param, e.msg);
          });
        }

      })
      .catch(function (error) {
        dispatch({ type: FETCH_SUCCESS, payload: 'Internal server error.' });
        openNotificationWithIcon('error', 'Sign in', 'Somthing went wrong, try again.');
        // console.log("Error****:", error.message);
      });
  }
};

export const verifyToken = (token) => {
  const verifyData = new FormData();
  verifyData.append('token', token);
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(apiUrl + 'api/v2/user/verify-token', verifyData)
      .then((response) => {
        // console.log(response.data.valid);
        if(!(response && response.data && response.data.valid)) {
          dispatch({ type: SIGNOUT_USER_SUCCESS });
        }
      })
      .catch(function (error) {
        // dispatch({ type: SIGNOUT_USER_SUCCESS });
        // console.log("Error****:", error);
      });
  }
};
