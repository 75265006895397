import { FETCH_ADS } from "../../constants/ActionTypes";

const INIT_STATE = {
  ads: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case FETCH_ADS: {
      return { ...state, ads: action.payload };
    }

    default:
      return state;
  }
}
