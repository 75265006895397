import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { decryptString } from '../../helper/cryptograpgy';
import { userSignOut } from "../../appRedux/actions/Auth";

import axios from 'axios';
import { config } from '../../constants/config';
import { DB_PREFIX } from '../../config/apiConfig';
let apiUrl = config.url.API_URL;

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {
  const dispatch = useDispatch();
  let { navStyle, themeType, pathname } = useSelector(({ settings }) => settings);
  let { token, permission } = useSelector(({ auth }) => auth);

  if (permission) {
    // console.log('permission', permission);
    permission = decryptString(permission);
    // console.log('permission', permission);
    if (!permission) {
      dispatch(userSignOut());
    }
  } else {
    dispatch(userSignOut());
  }

  useEffect(() => {
    let mounted = true;
    const verifyData = new FormData();
    verifyData.append('token', token);

    axios.post(apiUrl + 'api/v2/user/verify-token', verifyData)
      .then((response) => {
        if (mounted) {
          // console.log(response.data.valid);
          if (!(response && response.data && response.data.valid)) {
            dispatch(userSignOut());
          }
        }
      })
      .catch(function (error) {
        if (mounted) {
          dispatch(userSignOut());
          // console.log("Error****:", error);
        }
      });
    return () => {
      mounted = false;
    }
  }, []);


  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const getDefaultOpenKeys = (selectedKeys) => {
    if (selectedKeys == 'dashboard') {
      return "dashboard";
    }
    if (selectedKeys == 'all-posts' || selectedKeys == 'add-post' || selectedKeys.includes('edit-post') || selectedKeys == 'all-categories' || selectedKeys == 'all-tags' || selectedKeys == 'comments' || selectedKeys == 'post-sections') {
      return "posts";
    }
    if (selectedKeys == 'all-photos' || selectedKeys == 'add-photo' || selectedKeys.includes('edit-photo')) {
      return "photos";
    }
    if (selectedKeys == 'all-teams' || selectedKeys == 'add-team' || selectedKeys.includes('edit-team')) {
      return "teams";
    }
    if (selectedKeys == 'all-series' || selectedKeys == 'add-series' || selectedKeys.includes('edit-series')) {
      return "series";
    }
    if (selectedKeys == 'all-matches' || selectedKeys == 'add-match' || selectedKeys.includes('edit-match')) {
      return "matches";
    }
    if (selectedKeys == 'all-players' || selectedKeys == 'add-player' || selectedKeys.includes('edit-player')) {
      return "players";
    }
    if (selectedKeys == 'all-polls' || selectedKeys == 'add-poll' || selectedKeys.includes('edit-poll')) {
      return "polls";
    }
    if (selectedKeys == 'all-pages' || selectedKeys == 'add-page' || selectedKeys.includes('edit-page') || selectedKeys == 'about' || selectedKeys == 'about-content' || selectedKeys == 'about-testimonial') {
      return "pages";
    }
    if (selectedKeys == 'menu-items' || selectedKeys == 'header-menu' || selectedKeys == 'footer-menu') {
      return "menus";
    }
    if (selectedKeys == 'all-users' || selectedKeys == 'add-user' || selectedKeys.includes('edit-user') || selectedKeys == 'profile') {
      return "users";
    }
    if (selectedKeys == 'all-guests' || selectedKeys == 'add-guest' || selectedKeys.includes('edit-guest')) {
      return "guests";
    }
    if (selectedKeys == 'all-subscribers' || selectedKeys == 'send-message-to-subscriber') {
      return "subscribers";
    }
    if (selectedKeys == 'advertise') {
      return "advertise";
    }
    if (selectedKeys == 'all-contacts') {
      return "all-contacts";
    }
    if (selectedKeys == 'internal-links') {
      return "internal-links";
    }
    if (selectedKeys == 'site-options') {
      return "site-options";
    }
    return "posts";
  }

  return (<>

    <SidebarLogo />
    <div className="gx-sidebar-content">
      <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
        <UserProfile />
      </div>
      <CustomScrollbars className="gx-layout-sider-scrollbar">
        <Menu
          defaultOpenKeys={[getDefaultOpenKeys(selectedKeys)]}
          selectedKeys={[selectedKeys]}
          theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
          mode="inline">

          <Menu.Item key="dashboard">
            <Link to="/dashboard"><i className="icon icon-dasbhoard" /> Dashboard</Link>
          </Menu.Item>

          <MenuItemGroup key="main" className="gx-menu-group" title="Main">

            {permission && (permission === 'administrator' || permission === 'editor' || permission === 'author' || permission === 'contributor') &&
              <SubMenu key="posts" popupClassName={getNavStyleSubMenuClass(navStyle)} title={<span> <i className="icon icon-edit" /> <span>Posts</span></span>}>
                <Menu.Item key="all-posts">
                  <Link to="/all-posts">All Posts</Link>
                </Menu.Item>
                <Menu.Item key="add-post">
                  <Link to="/add-post">Add Post</Link>
                </Menu.Item>
                {permission && (permission == 'administrator' || permission == 'editor') &&
                  <Menu.Item key="all-categories">
                    <Link to="/all-categories">Categories</Link>
                  </Menu.Item>
                }
                {permission && (permission == 'administrator' || permission == 'editor') &&
                  <Menu.Item key="all-tags">
                    <Link to="/all-tags">Tags</Link>
                  </Menu.Item>
                }
                {permission && (permission == 'administrator' || permission == 'editor') &&
                  <Menu.Item key="comments">
                    <Link to="/comments">Comments</Link>
                  </Menu.Item>
                }
                {permission && (permission == 'administrator') &&
                  <Menu.Item key="post-sections">
                    <Link to="/post-sections">Post Sections</Link>
                  </Menu.Item>
                }
              </SubMenu>
            }

            {permission && (permission === 'administrator' || permission === 'editor') && DB_PREFIX == "bden" &&
              <SubMenu key="photos" popupClassName={getNavStyleSubMenuClass(navStyle)} title={<span> <i className="icon icon-image" /> <span>Photos</span></span>}>

                <Menu.Item key="all-photos">
                  <Link to="/all-photos">All Photos</Link>
                </Menu.Item>

                <Menu.Item key="add-photo">
                  <Link to="/add-photo">Add Photo</Link>
                </Menu.Item>
              </SubMenu>
            }

            {permission && (permission === 'administrator' || permission === 'editor') && DB_PREFIX == "bden" &&
              <SubMenu key="videos" popupClassName={getNavStyleSubMenuClass(navStyle)} title={<span> <i className="icon icon-extensions" /> <span>Videos</span></span>}>

                <Menu.Item key="all-videos">
                  <Link to="/all-videos">All Video</Link>
                </Menu.Item>

                <Menu.Item key="add-video">
                  <Link to="/add-video">Add Video</Link>
                </Menu.Item>
              </SubMenu>
            }

            {permission && (permission === 'administrator') &&
              <SubMenu key="polls" popupClassName={getNavStyleSubMenuClass(navStyle)} title={<span> <i className="icon icon-apps" /> <span>Polls</span></span>}>
                <Menu.Item key="all-polls">
                  <Link to="/all-polls">All Polls</Link>
                </Menu.Item>

                <Menu.Item key="add-poll">
                  <Link to="/add-poll">Add Poll</Link>
                </Menu.Item>
              </SubMenu>
            }

            {permission && (permission === 'administrator') &&
              <SubMenu key="pages" popupClassName={getNavStyleSubMenuClass(navStyle)} title={<span> <i className="icon icon-wysiwyg" /> <span>Pages</span></span>}>
                <Menu.Item key="all-pages">
                  <Link to="/all-pages">All Pages</Link>
                </Menu.Item>
                <Menu.Item key="add-page">
                  <Link to="/add-page">Add Page</Link>
                </Menu.Item>
                <SubMenu key="about" popupClassName={getNavStyleSubMenuClass(navStyle)} title={<span> <span>About</span></span>}>
                  <Menu.Item key="about-content">
                    <Link to="/about-content">Content</Link>
                  </Menu.Item>

                  <Menu.Item key="about-testimonial">
                    <Link to="/about-testimonial">Testimonial</Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="faqs">
                  <Link to="/faqs">Faq</Link>
                </Menu.Item>
              </SubMenu>
            }

            {permission && (permission === 'administrator') &&
              <SubMenu key="menus" popupClassName={getNavStyleSubMenuClass(navStyle)} title={<span> <i className="icon icon-apps-new" /> <span>Menus</span></span>}>
                <Menu.Item key="menu-items">
                  <Link to="/menu-items">Menu Items</Link>
                </Menu.Item>

                <Menu.Item key="header-menu">
                  <Link to="/header-menu">Header Menu</Link>
                </Menu.Item>

                <Menu.Item key="footer-menu">
                  <Link to="/footer-menu">Footer Menu</Link>
                </Menu.Item>

                <Menu.Item key="copyright-menu">
                  <Link to="/copyright-menu">Copyright Menu</Link>
                </Menu.Item>
              </SubMenu>
            }

            {permission && (permission === 'administrator' || permission === 'editor' || permission === 'author') &&
              <Menu.Item key="internal-links">
                <Link to="/internal-links"><i className="icon icon-link" />Internal Links</Link>
              </Menu.Item>
            }

            {/* {permission && (permission === 'administrator') &&
              <Menu.Item key="image-optimization">
                <Link to="/image-optimization"><i className="icon icon-donut" />Image Optimization</Link>
              </Menu.Item>
            } */}

          </MenuItemGroup>

          {permission && (permission === 'administrator') && DB_PREFIX == "bden" &&
            <MenuItemGroup key="Score Api" className="gx-menu-group" title="Score Api">
              {permission && (permission === 'administrator') &&
                <SubMenu key="teams" popupClassName={getNavStyleSubMenuClass(navStyle)} title={<span> <i className="icon icon-basic-calendar" /> <span>Teams</span></span>}>
                  <Menu.Item key="all-teams">
                    <Link to="/all-teams">All Teams</Link>
                  </Menu.Item>

                  <Menu.Item key="add-team">
                    <Link to="/add-team">Add Team</Link>
                  </Menu.Item>
                </SubMenu>
              }

              {permission && (permission === 'administrator') &&
                <SubMenu key="series" popupClassName={getNavStyleSubMenuClass(navStyle)} title={<span> <i className="icon icon-cards-list-view" /> <span>Series</span></span>}>
                  <Menu.Item key="all-series">
                    <Link to="/all-series">All Series</Link>
                  </Menu.Item>

                  <Menu.Item key="add-series">
                    <Link to="/add-series">Add Series</Link>
                  </Menu.Item>
                </SubMenu>
              }

              {permission && (permission === 'administrator') &&
                <SubMenu key="matches" popupClassName={getNavStyleSubMenuClass(navStyle)} title={<span> <i className="icon icon-ticket-new" /> <span>Matches</span></span>}>
                  <Menu.Item key="all-matches">
                    <Link to="/all-matches">All Matches</Link>
                  </Menu.Item>

                  <Menu.Item key="add-match">
                    <Link to="/add-match">Add Match</Link>
                  </Menu.Item>
                </SubMenu>
              }

              {permission && (permission === 'administrator') &&
                <SubMenu key="players" popupClassName={getNavStyleSubMenuClass(navStyle)} title={<span> <i className="icon icon-contacts" /> <span>Players</span></span>}>
                  <Menu.Item key="all-players">
                    <Link to="/all-players">All Players</Link>
                  </Menu.Item>

                  <Menu.Item key="add-player">
                    <Link to="/add-player">Add Player</Link>
                  </Menu.Item>
                </SubMenu>
              }
            </MenuItemGroup>
          }

          <MenuItemGroup key="Users" className="gx-menu-group" title="Users">
            {permission &&
              <SubMenu key="users" popupClassName={getNavStyleSubMenuClass(navStyle)} title={<span> <i className="icon icon-user" /> <span>Users</span></span>}>
                {permission && permission === 'administrator' &&
                  <Menu.Item key="all-users">
                    <Link to="/all-users">All Users</Link>
                  </Menu.Item>
                }
                {permission && permission === 'administrator' &&
                  <Menu.Item key="add-user">
                    <Link to="/add-user">Add User</Link>
                  </Menu.Item>
                }
                <Menu.Item key="profile">
                  <Link to="/profile">Profile</Link>
                </Menu.Item>
              </SubMenu>
            }

            {/* {permission && permission === 'administrator' &&
              <SubMenu key="guests" popupClassName={getNavStyleSubMenuClass(navStyle)} title={<span> <i className="icon icon-listing-dbrd" /> <span>Guests</span></span>}>
                <Menu.Item key="all-guests">
                  <Link to="/all-guests">All Guests</Link>
                </Menu.Item>
                <Menu.Item key="add-guest">
                  <Link to="/add-guest">Add Guest</Link>
                </Menu.Item>
              </SubMenu>
            } */}

            {permission && permission === 'administrator' &&
              <SubMenu key="subscribers" popupClassName={getNavStyleSubMenuClass(navStyle)} title={<span> <i className="icon icon-listing-dbrd" /> <span>Subscriptions</span></span>}>
                <Menu.Item key="all-subscribers">
                  <Link to="/all-subscribers">Subscribers List</Link>
                </Menu.Item>
                <Menu.Item key="send-message-to-subscriber">
                  <Link to="/send-message-to-subscriber">Send Message</Link>
                </Menu.Item>
              </SubMenu>
            }

            {permission && permission === 'administrator' &&
              <Menu.Item key="all-contacts">
                <Link to="/all-contacts"><i className="icon icon-anchor" /> Manage Contacts</Link>
              </Menu.Item>
            }
          </MenuItemGroup>


          {permission && permission === 'administrator' &&
            <MenuItemGroup key="Website" className="gx-menu-group" title="Website">
              {permission && permission === 'administrator' &&
                <Menu.Item key="advertise">
                  <Link to="/ads"><i className="icon icon-refer" /> Manage Ads</Link>
                </Menu.Item>
              }
              {permission && permission === 'administrator' &&
                <Menu.Item key="site-options">
                  <Link to="/site-options"><i className="icon icon-widgets" /> Site Options</Link>
                </Menu.Item>
              }
            </MenuItemGroup>
          }
        </Menu>
      </CustomScrollbars>
    </div>
  </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;

