import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";
import { Link } from "react-router-dom";
import { decryptString } from "../../helper/cryptograpgy";

const UserProfile = () => {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  let { permission } = useSelector(({ auth }) => auth);
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li><Link to="/profile">My Account</Link></li>
      {/* <li><Link to="/signin">Logout</Link></li> */}
      <li><a onClick={() => {
        dispatch(userSignOut());
      }} className="d-block">Logout</a></li>
    </ul>
  );

  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-2 gx-avatar-row">
      <Popover placement="bottomLeft" content={userMenuOptions} trigger="click" className="d-flex align-items-center">
        <Avatar src='https://via.placeholder.com/150x150' className="gx-size-40 gx-pointer gx-mr-3" alt="" />
        <span className="d-inline-block">
          <span className="gx-avatar-name d-flex align-items-center text-uppercase text-white">{authUser && authUser.user_login}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
          <small className="gx-avatar-name d-flex text-capitalize">{decryptString(permission)}</small>          
        </span>
      </Popover>
    </div>

  )
};

export default UserProfile;
