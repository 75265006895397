import {
  FETCH_COMMETN_REPLY, FETCH_COMMETN_REPLY_COUNT
} from "../../constants/ActionTypes";
const INIT_STATE = {
  commentReply: [],
  commentReplyCount: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case FETCH_COMMETN_REPLY: {
      return { ...state, commentReply: action.payload };
    }
    case FETCH_COMMETN_REPLY_COUNT: {
      return { ...state, commentReplyCount: action.payload };
    }

    default:
      return state;
  }
}
