import React, { Component, useEffect, useState } from "react";
import Nprogress from "nprogress";
import ReactPlaceholder from "react-placeholder";
import "nprogress/nprogress.css";

import "react-placeholder/lib/reactPlaceholder.css";
import CircularProgress from "components/CircularProgress";

export default function asyncComponent(importComponent) {

  // console.log('importComponent', importComponent);
  const AsyncFunc2 = () => {

    const [component, setComponent] = useState(null);

    Nprogress.start();

    useEffect(() => {
      let mounted = true;
      Nprogress.done();
      async function getImportedComponent() {
        const { default: Component } = await importComponent();
        return Component;
      }

      if (mounted) {
        setComponent(getImportedComponent());
      }
      return () => {
        mounted = false;
      }
    }, []);

    return (<>
      {component ?
        <ReactPlaceholder type="text" rows={7} ready={component !== null}>
          {component}
        </ReactPlaceholder> :
        <CircularProgress />
      }
    </>)
  }

  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null
      };
    }

    componentWillMount() {
      Nprogress.start();
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    async componentDidMount() {
      this.mounted = true;
      const { default: Component } = await importComponent();
      Nprogress.done();
      if (this.mounted) {
        this.setState({
          component: <Component {...this.props} />
        });
      }
    }

    render() {
      const Component = this.state.component || <CircularProgress />;
      return (
        <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
          {Component}
        </ReactPlaceholder>
      );
    }
  }

  return AsyncFunc;
}
