import { FETCH_ADMIN, FETCH_USER, FETCH_USER_ALL, FETCH_USER_ADMINISTRATOR, FETCH_USER_EDITOR, FETCH_USER_AUTHOR, FETCH_USER_CONTRIBUTOR, FETCH_USER_SUBSCRIBER } from "../../constants/ActionTypes";

const INIT_STATE = {
  allAdmin: null,
  allUser: null,
  allUserCount: 0,
  adminCount: 0,
  editorCount: 0,
  authorCount: 0,
  subscriberCount: 0,
  contributorCount: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case FETCH_ADMIN: {
      return { ...state, allAdmin: action.payload };
    }
    case FETCH_USER: {
      return { ...state, allUser: action.payload };
    }

    case FETCH_USER_ALL: {
      return { ...state, allUserCount: action.payload };
    }
    case FETCH_USER_ADMINISTRATOR: {
      return { ...state, adminCount: action.payload };
    }
    case FETCH_USER_EDITOR: {
      return { ...state, editorCount: action.payload };
    }
    case FETCH_USER_AUTHOR: {
      return { ...state, authorCount: action.payload };
    }
    case FETCH_USER_CONTRIBUTOR: {
      return { ...state, subscriberCount: action.payload };
    }
    case FETCH_USER_SUBSCRIBER: {
      return { ...state, contributorCount: action.payload };
    }

    default:
      return state;
  }
}
