import { FETCH_POST_SETTINGS } from "../../constants/ActionTypes";

const INIT_STATE = {
  postSections: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case FETCH_POST_SETTINGS: {
      return { ...state, postSections: action.payload };
    }

    default:
      return state;
  }
}
