import {
  FETCH_COMMETNS, FETCH_COMMENT_COUNT, FETCH_MINE_COMMENT_COUNT, FETCH_PENDING_COMMENT_COUNT, FETCH_APPROVED_COMMENT_COUNT, FETCH_SPAM_COMMENT_COUNT, FETCH_TRASHED_COMMENT_COUNT, ADD_COMMENT
} from "../../constants/ActionTypes";
const INIT_STATE = {
  comments: [],
  allCommentCount: null,
  mineCommentCount: null,
  pendingCommentCount: null,
  approvedCommentCount: null,
  spamCommentCount: null,
  trashedCommentCount: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case FETCH_COMMETNS: {
      return { ...state, comments: action.payload };
    }

    case FETCH_COMMENT_COUNT: {
      return { ...state, allCommentCount: action.payload };
    }
    case FETCH_MINE_COMMENT_COUNT: {
      return { ...state, mineCommentCount: action.payload };
    }
    case FETCH_PENDING_COMMENT_COUNT: {
      return { ...state, pendingCommentCount: action.payload };
    }
    case FETCH_APPROVED_COMMENT_COUNT: {
      return { ...state, approvedCommentCount: action.payload };
    }
    case FETCH_SPAM_COMMENT_COUNT: {
      return { ...state, spamCommentCount: action.payload };
    }
    case FETCH_TRASHED_COMMENT_COUNT: {
      return { ...state, trashedCommentCount: action.payload };
    }

    case ADD_COMMENT: {
      return {
        ...state,
        comments: [action.payload, ...state.comments]
      }
    }

    default:
      return state;
  }
}
