import { FETCH_MENU_GROUP } from "../../constants/ActionTypes";

const INIT_STATE = {
  menuGroups: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_MENU_GROUP: {
      return { ...state, menuGroups: action.payload };
    }

    default:
      return state;
  }
}
